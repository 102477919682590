/* These styles are used to customize the InfoModal and ErrorModal */

.custom-modal-footer {
  border: none;
}

.modal-backdrop-custom {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darkens the background */
    backdrop-filter: blur(5px); /* Blurs the background */
     z-index: 1040;  /* Ensure it's behind the modal */
   
  }

  /* Customizing the Edit Email and Followup component and modal */

  
  .edit-email-form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .edit-email-form-label {
    align-self: flex-start;
    margin-bottom: 5px;
  }
  
  .edit-email-form-textarea {
    width: 100%;
    min-height: 3em;
    margin-bottom: 10px;
  }
  
  .edit-email-form-button {
    align-self: flex-start;
  }

  .modal-capsule-text {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    margin: 5px 0;
    background-color: #f0f4f8; /* Light background color */
    border-radius: 5px; /* Rounded corners for capsule effect */
    border: 1px solid #d1e1f0; /* Optional border for added definition */
    font-size: 0.9em; /* Adjust font size as needed */
    color: #333; /* Text color */
    white-space: normal;
}

  .modal-capsule-text i {
      margin-right: 8px; /* Space between the icon and the text */
      color: #007bff; /* Icon color */
  }

/*=========================== Customizing email conversation bubbles in EmailConversationModal ============================ */

.conversation-modal-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 100%; 
  overflow: hidden  
}

.email-content-bubble {
  display: inline-block;
  text-wrap: wrap;
  padding: 0.5em 0.5em;
  background-color: #eef2fa;
  border-radius: 5px;
  border: 1px solid #ccc
}


.email-conversation-modal {
  min-width: 40%;
}

.cleaned-text-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
  margin-bottom: 2em;
}

.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em; 
}

.form-check-input {
  transform: scale(1.2);

}


 /*=========================== Customizing the loading icon component modal ============================ */


.loader {
  /* TODO: Style this so it fits the rest of the application */
  width: 2.5em;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 0.5em solid lightblue;
  border-right-color: orange;
  animation: l2 1s infinite linear;
}
@keyframes l2 {to{transform: rotate(1turn)}}