/* Parent-div currently not used */
.parent-div {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    align-items: center;
  }
  
  .button-div {
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }

  .button-div-active {
    background-color: #007bff;
    color: white;
  }
  
  .button-div:hover {
    background-color: #f0f0f0; /* Highlight color */
  }

  .bulk-email-editor {
    outline: none;
    border: 1px solid #ccc; /* Adds a small, consistent border */
    border-radius: 4px; /* Optional: Rounds the edges slightly */
    padding: 8px; /* Optional: Adds padding inside the editor */
  }