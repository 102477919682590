/* Inline Error styling */

.inline-error-container {
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
  border: 2px solid #dc3545;
  border-radius: 0.375rem;
  background-color: #dc3545;
  color: #dc3545;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.inline-error-message {
  font-weight: 500;
  margin-right: 1rem;
  color: white;
}

/* Close Button Styling */
.error-close-btn {
   border: none; 
   background-color: transparent;
   font-size: 1.5em; 
   color: white;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.error-close-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
}
