

/* =================== CUSTOMIZING EMAIL ADDRESS LISTS ======================= */


.address-list-button {
  border: 2px solid transparent;  
  border-radius: 6px;         
  padding: 0.4em 0.4em;         
  background-color: #fff;     
  color: #3d3d3d;             
  cursor: pointer;            
  display: flex;
  font-weight: 400;
  font-size: 1.2em;
  align-items: center;
  gap: 0.3em;                   
  transition: background-color 0.3s ease, color 0.3s ease; 
}

@media (max-width: 1024px) {
  .address-list-button {
    font-size: 1.2em; /* Adjust the font size for smaller screens */
    gap: 0.2em;
  }
}

.address-list-button:hover {
  /* background-color: #007bff;   */
  color: #007bff; 
  border: 2px solid #007bff;              
}

.address-list-button i {
  font-size: 1.1em;           
  -webkit-text-stroke: 0.05em;
}
/* =================== CUSTOMIZING THE CAMPAIGN LIST TAB BAR ======================= */

/* TODO: Change the #campaignList below to be classes, since too many components have the same id */


#campaignList th, #campaignList td {
  vertical-align: middle;
}

#campaignList td {
  text-align: center; 
  padding: 0.4em 0.4em;
}


#pills-tab {
    display: flex;
    justify-content: center; 
    gap: 5em; 
    line-height: 2em; 
}


.campaign-type-tab {
    display: flex;
    align-items: center; 
    color: #3e3f3f;
    font-size: 1em;
    
}

.icon-campaign-list-btn {
  vertical-align: middle;
  margin-right: 8px;
  text-align: left;
}

.nav-link.active {
    background-color: #fcfeff00 !important;
    color: #007bff !important; 
    border-color: #007bff !important; 
    border: 2px solid #007bff !important;
    border-radius: 4px; 
    font-weight: 500;
}

.nav-link.active i {
font-size: 1em;           
-webkit-text-stroke: 0.03em;
}
.nav-link:not(.active):hover {
    color: #007bff; 
    border-color: #007bff; 
    border: 2px solid #007bff !important;
    border-radius: 4px; 
}
.nav-link:not(.active) {
  border: 2px solid #ffffff00 !important;
  border-radius: 4px; 
}



.fixed-width-btn {
    width: 80%; /* Set the desired width */
  }


/* ==================== TABLE CUSTOMIZATION SECTION ======================= */

/* ==================== Customize the campaign columns and column heads ==================== */

.thead-light th {
    color: #45494e;
    border-color: #e9ecef;
     background-color: white; 
    font-weight: 900;
    font-size: 1.1em;
    text-transform: uppercase;
    width: 10%;
    text-align: center;
}

/* ==================== PRICING TABLE SECTION ======================= */

.bg-simple {
  position: relative;
  background: linear-gradient(135deg, #fdfdfd, #eeeeee);
  overflow: hidden;
  color: #333;
}

.bg-simple::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%; /* Start the light outside the view */
  width: 150%;
  height: 100%;
  background: linear-gradient(90deg, rgba(112, 102, 102, 0) 0%, rgba(221, 221, 221, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shine 3s infinite;
}

@keyframes shine {
  from {
    left: -150%;
  }
  to {
    left: 150%;
  }
}
