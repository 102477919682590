/* ================================ app bar styling ================================ */

.campaign-options-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.25rem; /* pt-1 */
  padding-bottom: 0.25rem; /* pb-1 */
  margin-left: 0.5rem; /* ms-2 */
  gap: 0.5rem;
}


/* ================================ BUTTON STYLING ================================ */

.table-bar-button {
    border: 2px solid transparent;  
    border-radius: 6px;         
    padding: 0.4em 0.4em;         
    background-color: #fff;     
    color: #3d3d3d;             
    cursor: pointer;            
    display: flex;
    font-weight: 400;
    font-size: 0.9em;
    align-items: center;
    gap: 0.3em;                   /* Space between icon and text */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }

  @media (max-width: 1024px) {
    .table-bar-button {
      font-size: 0.7em; /* Adjust the font size for smaller screens */
      gap: 0.2em;
    }
  }
  
  .table-bar-button:hover {
    color: #007bff; 
    border: 2px solid #007bff;              
  }
  
  .table-bar-button i {
    font-size: 1.1em;           
    -webkit-text-stroke: 0.05em;
  }

  .campaign-name-input {
    border: none;
    font-weight: 500;
    font-size: 1.1em;
    color: #777777;
    border: 1px solid transparent; /* Add bottom border */
  }
  .campaign-name-input:hover {
    cursor: text; /* Change cursor to text */
    border: 1px solid #202020; /* Add bottom border */
    background-color: #f1f1f1; /* Light blue background */

  }


  /* ================================ DROP-DOWN STYLING =============================*/


  .dropdown-list-item:hover{
    background-color: #007bff; 
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .dropdown-list-item:hover button{
    background-color: transparent; 
    color: white;
  }

  .dropdown-list-item button {
    border: 2px solid transparent;  
    border-radius: 6px;         
    padding: 0.4em 0.4em;         
    background-color: transparent;     
    cursor: pointer;            
    display: flex;
    font-weight: 500;
    font-size: 0.9em;
    align-items: center;
    gap: 0.3em;                   
  }

/* ========================= STYLING FOR THE CAMPAIGN TABLE ========================= */


.manage-campaign-component {
  font-size: 1em;
}

.campaign-table-container {
  overflow-x: auto;
  white-space: nowrap; /* Prevents content from wrapping */
}



.resizer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.2em;
  background: rgb(82, 111, 236);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: rgb(230, 162, 74);
  opacity: 1;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;  /* Adjust the width as necessary */
  height: 100%;
  cursor: col-resize;
  background-color: rgba(0, 0, 0, 0.1);  
  display: flex;
  align-items: center;
  justify-content: center;
}


.editable-cell-btn {
  padding: 0em 0.6em;
  font-size: 0.9em;
  cursor: pointer;

}


.table .manage-campaign-td {
  padding-right: 0.1em;
  padding-bottom: 0.1em;
  position: relative;
}



.table-editable-cell {
  border: none;
  outline: none;
  width: 100%; 
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.table-editable-cell-collapsed {
  border: none;
  outline: none;
  width: 100%; 
  display: inline-block;
  overflow: hidden; 
  white-space: nowrap;
  max-height: 2em;

}

.table-editable-cell textarea:focus {
  border: none;
  outline: none;
}


.table-static-cell textarea {
  border: none;
  width: 100%; 
  resize: none;
  box-sizing: border-box; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: normal;
  word-wrap: break-word;
  /* font-size: 0.9em; */
}

.table-static-cell textarea:focus {
  /* border: 1px solid transparent; */
  border: none;
  outline: none;
}

.table .manage-campaign-th {
  /* padding: 0.5em 0.5em; */
  padding-right: 0.1em;
  overflow: hidden;
  position: relative;

}

/* ========================= STYLING FOR COLUMN VISIBILITY ========================= */

.table-pagination-container {
  display: flex;
  justify-content: center;
  padding-right: 10em;
}